/* TODO Add site wide styles */
.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .content-container {
    flex: 1;
    display: flex;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    overflow: auto;
    border: 1px solid #f0ebf4;
  }

body {
  background-color: #a1c3d1;
  color: black;
  height: 100%;
  margin: 0;
  padding: 0;
}