.update-form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    max-width: 600px;
    color: #444444;
    padding: 1rem;
    background-color: #a1c3d1;
    border-radius: 10px;
}

.errors-update {
    color: red;
    display: flex;
    font-weight: bold;
    font-family: cursive;
    justify-content: center;
    align-items: center;
}
.update-form label {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
    color: #444444;
    font-weight: bold;
    font-family: cursive;
    font-size: 16px;
}

.update-form input,
.update-form select,
.update-form textarea {
    padding: 0.5rem;
    border: 1px solid #ddd;
    font-size: 14px;
    background-color: #f0ebf4;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    max-width: 200px;
}

.update-form input[type='checkbox'] {
    margin-top: 0.5rem;
    margin-left: 5px;
    align-self: flex-start;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.update-form button {
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 0.5rem;
    background-color: #f172a1;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-family: cursive;
}

.server-form button:hover {
    background-color: #eaf2d7;
}


.update-form h2 {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    font-family: cursive;
    align-items: center;
    justify-content: center;
    font-family: cursive;
}


.update-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}