.login-form {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.login-form label {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 8px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}

.login-form input {
    text-align: center;
    padding: 0.5rem;
    border-radius: 5px;
    width: 200px;
    font-size: large;
    font-family: 'Courier New', Courier, monospace;
}

.login-form button {
    margin: 5px;
    padding: 0.5rem;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}

.login-form button:hover {
    background-color: #eaf2d7;
    cursor: pointer;
}

.login-buttons-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: .5rem;
}

.login-submit-btn,
.demo-btn {
    margin-left: 14px;
    margin-right: 15px;
}


h2 {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: -10px;
    font-family: 'Indie Flower', cursive;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: xx-large;
}

.signup-redirect-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-redirect-btn button {
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: small;
    color: blue;
    text-decoration: none;
    cursor: pointer;
    margin-left: 15px;
}

.sign-in-box {
    background-color: #A1c3D1;
}

#modal-content {
    background-color: #A1c3D1;
}

.no-message-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.login-header {
    display: flex;
    width: 30rem
}

.login-img{
    width: 250px;
    height: 250px;
    margin-top: 20px;
    margin-right: 20px;
    align-items: flex-end;
}

.welcome-back-txt{
    align-items: left;
    padding-top: 150px;
    justify-content: flex-end;
}

.h2-text {

}

.arrow-down {
    padding: 1rem;
}

.terms-div {
    background-color: #F172A1;
    color: #F0EBF4;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

input {
    text-align: center;
}
