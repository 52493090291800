.avatar:hover + .alt-text {
    opacity: 1; 
}

.alt-text {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    background-color: #dfb2f4;
    color: #fff; 
    padding: 4px 8px; 
    border-radius: 4px; 
    font-size: 12px; 
    opacity: 0; 
    transition: opacity 0.3s ease-in-out; 
}


.server-navbar {
    display: flex;
    background-color: #b39bc8;
    padding: 8px;
    border-right: 1px solid #202225;
}

.server-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.server-item {
    margin-bottom: 8px;
}

.server-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    padding: 6px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.server-link:hover {
    background-color: #e64398;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
}

.server-navbar button {
    background-color: #e64398;
    padding: 6px;
    margin-left: 8px;
    border: none;
    color: #f0ebf4;
    font-weight: bold;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.open-modal-button:hover::after {
    content: "Create new server";
    position: absolute;
    margin-top: 8px;
    background-color: #333;
    display: flex;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}


.server-navbar button:hover {
    background-color: #f172a1;
}