.curr-server {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: auto;
    border-bottom: 4px solid black;
    width: 100%;
}

.server-name {
    font-size: 30px;
    margin-bottom: 10px;
    font-family: 'Tilt Prism', cursive;;
    color: #f0ebf4;
    border: 2px solid black;
    padding: 10px;
    margin-right: 350px;
}

@media screen and (max-width: 1100px) {
    .server-name {
        margin-right: 100px;
    }
    .buttons-container {
        margin-left: 100px;
    }


}


.buttons-container {
    display: flex;
    justify-content: flex-end;
    justify-items: center;
    margin-top: 10px;
    margin-left: 380px;
}

.button-1 button {
    margin-left: 10px;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #f172a1;
}

.button-2 button {
    border-radius: 4px;
    margin-left: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #e64398;
}

.channels-container {
    display: flex;
    margin-top: 20px;
    align-items: flex-start;
}
  /* Example styles for the trash icon using FontAwesome */
.fa-trash {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
