.messages-wrapper {
    width: 80%
}

.message-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
    border: 1px solid #f0ebf4;
    border-radius: 5px;
    background-color: transparent;
  }
  .user-avatar-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #7289da;
  }
  
  .user-avatar {
    width: 32px; 
    height: 32px; 
    border-radius: 50%;
    margin-right: 10px;
  }

  .user-info {
    display: flex;
    align-items: flex-start;
    margin-right: auto;
  }
  
  .user-displayname {
    margin-left: 0;
    font-weight: 500;
    color: #7289da
  }
  
  .message-content {
    flex-grow: 1;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  
  textarea {
    width: 100%;
    height: 100px;
  }

  .button-container button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #7289da;
    color: #f0ebf4;
    font-weight: 500;
  }
  
  .button-container button:hover {
    background: #e64398;
  }

  .message-container .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
  }