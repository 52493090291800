.server-members {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.member-images {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
}

ul {
    list-style-type: none;
}
