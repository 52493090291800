.homepage-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-founders-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.about-founders-wrapper h1 {
    text-align: center;
    font-family: 'Moirai One', cursive;
    font-size: 3rem;
}

.about-founders-wrapper p {
    text-align: center;
    font-size: 1.5rem;
}

.about-founders-wrapper img {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
}

.founders-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

@media (max-width: 1000px) {
    .founders-cards {
        grid-template-columns: 1fr;
        /* Single column */
        gap: 20px;
        /* Adjust the gap as desired for small screens */
    }
}

.founders-cards p {
    display: flex;
    justify-content: center;
    font-size: 18px;
}

.founders-cards img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid black;
}

.tristan img {
    border: 4px solid #eac4d5;
}

.tristan h4 {
    font-family: 'Moirai One', cursive;
}

.alfonso img {
    border: 4px solid #ff686b;
}

.alfonso h4 {
    font-family: 'Moirai One', cursive;
}


.how-prod-works-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.how-prod-works-wrapper img {
    height: 100%;
    width: 100%;
    margin: 0px;
}

.webloiter-example {
    border:1px solid #ff686b;
}
