.pages {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.channels {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-right: 4px solid #b39bc8;
    padding: 20px;
    color: #f0ebf4;
    justify-content: center;
    align-items: center;
}

.create-channel {
    margin: 10px;
}

.new-channel input {
    margin: 5px;
}

.channel-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    font-family: 'Monomaniac One', sans-serif;
    color: #f0ebf4;
    font-size: 22px;
    border-bottom: 4px solid #b39bc8;
    padding: 20px;
}

.channel-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.channel-item {
    background-color: transparent;
    color: #202225;
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}
.new-channel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.channel-item:hover {
    background-color: #ebebeb;
}

.new-channel-buttons {
    display: grid;
    grid-template-areas:
        'input input'
        'create cancel';
}

.new-channel-input {
    grid-area: input;
    border: 2px solid #fdaac9;
}

.create-channel {
    grid-area: create;
}

.cancel-create-channel {
    grid-area: cancel;
}

.new-channel-button,
.create-channel,
.cancel-create-channel {
    background-color: #f172a1;
    color: #f0ebf4;
    border: none;
    padding: 15px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    font-family: 'Monomaniac One', sans-serif;
    border-radius: 4px;
}

.create-channel-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.users-list {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.user {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.create-dm-button {
    background-color: #677bc4;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.create-dm-button:hover {
    background-color: #506a9d;
}

.channel-input {
    margin-top: 10px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.create-channel-button {
    background-color: #677bc4;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.create-channel-button:hover {
    background-color: #506a9d;
}
