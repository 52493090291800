.signup-form {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.signup-form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: .5rem;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.passwords {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signup-container li {
    color: red;
}

.signup-form label {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-self: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 8px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}

.signup-form input {
    text-align: center;
    padding: 0.5rem;
    border-radius: 5px;
    width: 200px;
    font-size: small;
    font-family: 'Courier New', Courier, monospace;
}

.signup-form button {
    margin-bottom: 15px;
}

h2 {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: -10px;
    text-align: center;
}

.signup-form textarea {
    width: 250px;
    height: 150px;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace;

}

.login-redirect-btn {
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: small;
    color: blue;
    text-decoration: none;
    cursor: pointer;
    margin-left: 7.5px;
}

.terms-div {
    color: gray;
    font-size: small;
    margin: 20px
}

.signup-submit-btn{
    margin-left: 14px;
    margin-right: 15px;
}

.group-img{
    margin-left: 10px;
}
